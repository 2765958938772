<template lang="pug">
    div.col-lg-6.col-md-6.layout-spacing.mx-auto
        div.statbox.widget.box.box-shadow
            div.widget-header
                div.row
                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                            h4 {{ $t('attendinventory.complet')}}
                vue-form(:state="formstate")
                    div.widget-content.widget-content-area

                        div.form-group.mb-4
                            label(for="sParentCenter") {{ $t('attendinventory.requestcenter')}}
                            select.form-control(id="sParentCenter" v-model="model.idAttendCenter" @change="getInventoryRequest")
                                option(value="0" selected) {{ $t('headers.select')}}
                                option(:value=" Center.idCenter" v-for="Center in CenterList") {{ Center.sCenterName }}

                        div.form-group.mb-4
                            label(for="sParentCenter") {{ $t('attendinventory.invrequest')}}
                            select.form-control(id="inventoryRequest" v-model="model.idInventory")
                                option(value="0" selected) {{ $t('headers.select')}}
                                option(:value=" inventory.idInventory" v-for="inventory in InventoryRequests") {{ inventory.sCenterName + ' --> ' + inventory.iRequestInventory + ' unidades de inventario' }}

                        div.form-group.mb-4(v-if="model.idInventory != 0")
                            label()  {{ $t('attendinventory.requesadmin')}}
                            input.form-control(type="text" :value="InventoryRequests.find(o => o.idInventory === model.idInventory).sAdministratorRequest" readonly) 

                        div.form-group.mb-4(v-if="model.idInventory != 0")
                            label() {{ $t('attendinventory.requesinvent')}}
                            input.form-control(type="text"  :value="InventoryRequests.find(o => o.idInventory === model.idInventory).iRequestInventory" readonly)                                             

                        div.form-group.mb-4(v-if="model.idInventory != 0")
                            label()  {{ $t('attendinventory.requcomment')}}
                            input.form-control(type="text"  :value="InventoryRequests.find(o => o.idInventory === model.idInventory).sRequestComments" readonly)                                             


                        div.form-group.mb-4
                            label(for="iNumber") {{ $t('attendgroupInventory.nummanainv')}}
                            validate(tag="div")
                                input.form-control(id="iNumber" name="iNumber" :min="1" required type="number" placeholder="Numero de unidades de inventario" v-model="model.iNumberAttend" )
                                field-messages.text-danger(name="iNumber" )
                                    div(slot="required") {{ $t('attendgroupInventory.numcodreq')}}
                                    div(slot="min") {{ $t('attendgroupInventory.numcero')}}

                        div.form-group.mb-4
                            label(for="sComments") {{ $t('attendgroupInventory.comments')}}
                            validate(tag="div")
                                input.form-control(type="text" id="sComments" required name="sComments" placeholder="sComments" v-model="model.sAttendComments")
                                field-messages.text-danger(name="sComments" )
                                    div(slot="required") {{ $t('attendgroupInventory.commentsugg')}}
                        
                        button.btn.btn-secondary(type="button" @click="Save") {{ $t('btns.btnsend')}}                                                        


</template>
<script>
/* eslint-disable */
export default {
    name: 'AttendInventory',
    data() {
        return {
            formstate: {},
            CenterList: [],
            InventoryRequests: [],
            model: {
                idInventory: 0,
                idAttendCenter: 0,
                idAttendPerson: 0,
                iNumberAttend: 0,
                sAttendComments: ''
            }
        }
    },
    methods: {
        async getCenterList() {
            await axios.get(apiURL + 'Center/getCenterListByAdmin?idPerson=' + localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                //Aqui falta quitar el centro administrador, el no puede solicitar inventario
                console.log(response.data.centerList)
                this.CenterList = response.data.centerList
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },
        async getInventoryRequest() {
            await axios.get(apiURL + 'Center/requestInventoryByCenter?idCenter=' + this.model.idAttendCenter, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.InventoryRequests = response.data.centersList
            } )
            .catch( (error)  => {
                console.log("error")
            } )            
        },
        async Save() {
            this.model.idAttendPerson = localStorage.CertIdP

            this.openLoading(this.$t('msg.adminSwal1a'));

            await axios.post(apiURL + 'Center/attendInventory', this.model, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.bIsCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                Swal.fire(this.$t('msg.adminSwal1'))
                router.push('/pages/home')
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        },
    },
    mounted() {
        //this.checkPermisions(this.$router.currentRoute.path)
        this.getCenterList()
        }    
        
}
</script>